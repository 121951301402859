<script setup lang="ts">
interface Props {
  type?: "button" | "submit" | "reset" | undefined;
  spinner?: boolean;
  danger?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  type: "button",
});

const buttonColour = computed(() => {
  if (props.danger) return "h-btn-outline-danger";
  return "h-btn-outline";
});
</script>

<template>
  <button :type="type" class="h-btn" :class="buttonColour">
    <Icon v-if="spinner" name="svg-spinners:3-dots-move" class="h-6 w-6" />
    <slot v-else />
  </button>
</template>
